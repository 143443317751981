import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

$('.category-blk').each(function (item) {
    let title = `title:${$(this).find('h4').text()};`

    $(this).find('.glightbox').attr('data-glightbox', title)
})

function addGlightbox() {
    GLightbox({
        selector: ".glightbox",
        touchNavigation: true,
        loop: false,
        autoplayVideos: true,
        onOpen: () => {
            document.body.classList.remove('glightbox-open');
        }
    });
}

addGlightbox()